import React, { Component } from 'react';

// import assets and things we're gonna use
import '../css/Home.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import SettingsModal from './SettingsModal';

// "home" view
export default class Home extends Component 
{
    render()
    {
        return (
        <>
            {/* main container */}
            <Container className="Home">
                <h1>MEMORY MAYHEM</h1>
                <hr></hr>
                <img alt="Brain with glasses on" src={process.env.PUBLIC_URL + '/brain-png-4.png'} />
                <Container>
                    <Button variant="success" size="lg" className="btn" onClick={() => this.props.handler(true)}>Start Game</Button>  
                    <SettingsModal settings={this.props.settings} settingsHandler={this.props.settingsHandler}>Settings</SettingsModal> 
                </Container>  
            </Container>
        </>
        );
    }
}