import React, { Component } from 'react';

// resources
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../css/Main.css';

export default class Main extends Component
{
    // init component 
    constructor(props)
    {
        super(props); // although deprecated, use so we can access props in constructor

        console.log(props.settings);

        // game variables not related to the UI
        // static settings
        this.debug                      = true;         // enables/disables console
        this.startLives                 = Number(props.settings.startLives);
        this.timeBetweenFlashMultiplier = Number(props.settings.timeBetweenFlashMultiplier);
        this.timeToFlashMultiplier      = Number(props.settings.timeToFlashMultiplier);
        this.standardButtonColor        = String(props.settings.standardButtonColor);
        this.highlightedButtonColor     = String(props.settings.highlightedButtonColor);
        this.pointsOnCorrect            = Number(props.settings.pointsOnCorrect);
        this.pointsOnIncorrect          = Number(props.settings.pointsOnIncorrect);
        this.startRoundDelayTime        = Number(props.settings.startRoundDelayTime);
        this.timeToFlash                = Number(props.settings.timeToFlash);
        this.timeBetweenFlash           = Number(props.settings.timeBetweenFlash);

        // update timeToFlash based on _exp settings
        if (props.settings._expEnableRandomTimeToFlashGroups)
        {
            this.timeToFlash = props.settings._expTimeGroupMap[props.settings._expTimeToFlashGroup];
            console.log("stat exp overwritten timetoflash:", this.timeToFlash);
        }

        // dynamic
        this.sequenceArray      = [];
        this.sequenceCount      = 0;  // to count when verifying user-inputted sequences
        this.curTimeToFlash     = this.timeToFlash;
        this.curTimeBtwnFlash   = this.timeBetweenFlash;

        // game variables related to the UI
        this.state =        
        {
            lives: this.startLives,
            points: 0,
            level: 0,
            status: 'Waiting for user to start the game.',
            mainBtnsDisabled: true,
            startBtnDisabled: false,
            ctrlBtnsDisabled: false,
            btnColors: Array(9).fill(this.standardButtonColor)
        };

        // update console state based on debug
        if (!this.debug) console.log = () => { };
    }

    btnClick(num)
    {
        if (num === this.sequenceArray[this.sequenceCount])
        {
            console.log("input correct");
            this.sequenceCount++;
            this.setState(() => ({
                points: this.state.points + this.pointsOnCorrect  
            }), this.afterBtnClick);
        }
        else
        {
            console.log("input incorrect");
            this.setState(() => ({
                lives: this.state.lives - 1,
                points: this.state.points + this.pointsOnIncorrect
            }), this.afterBtnClick);
        }
    }

    afterBtnClick()
    {
        if (this.sequenceCount === this.sequenceArray.length)
        {
            console.log("round passed!!");

            // update internal vars
            this.curTimeToFlash   = Math.floor(this.curTimeToFlash * this.timeToFlashMultiplier);
            this.curTimeBtwnFlash = Math.floor(this.curTimeBtwnFlash * this.timeBetweenFlashMultiplier);

            // update UI
            this.setState(() => ({
                mainBtnsDisabled: true,
                startBtnDisabled: false,
                status: 'Round passed! Waiting for user to start the next round.'
            }));
        }
        else if (this.state.lives === 0)
        {
            console.log("game over!!");
            this.setState(() => ({
                mainBtnsDisabled: true,
                status: 'Game over! Waiting for user to reset the game.'
            }));
        }
    }

    startRound()
    {
        console.log("curTimeToFlash", this.curTimeToFlash, "curTimeBtwnFlash", this.curTimeBtwnFlash);
        // update UI
        this.setState(() => ({
            startBtnDisabled: true,
            ctrlBtnsDisabled: true,
            status: 'The sequence is being displayed!',
            level: this.state.level + 1
        }), this.generateSequence); // need callback b/c setState is async
    }

    resetGame()
    {
        console.log("game reset");

        this.curTimeToFlash     = this.timeToFlash;
        this.curTimeBtwnFlash   = this.timeBetweenFlash;

        this.setState(() => ({
            startBtnDisabled: false,
            mainBtnsDisabled: true,
            btnColors: Array(9).fill(this.standardButtonColor),
            lives: this.startLives,
            points: 0,
            level: 0,
            status: 'Waiting for user to start the game.'
        }));
    }

    generateSequence()
    {
        this.sequenceArray = [];
        this.sequenceCount = 0;
        const min = 1, max = 9;
        for (var i = 0; i < this.state.level; i++)
        {
            // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
            this.sequenceArray[i] = Math.floor(Math.random() * (max - min + 1) + min); 
        }

        setTimeout(() => this.displaySequence(this.sequenceArray), this.startRoundDelayTime);
    }

    async displaySequence(sequence)
    {
        console.log("displaying sequence to user", sequence);

        let tempArr = this.state.btnColors;
        for(var i = 0; i < sequence.length; i++)
        {
            console.log("displaying " + sequence[i]);
            // square color highlight
            tempArr[sequence[i] - 1] = this.highlightedButtonColor;
            this.setState(() => ({
                btnColors: tempArr
            }));
            await new Promise(resolve => setTimeout(resolve, this.curTimeToFlash));

            // square color unhighlight
            tempArr[sequence[i] - 1] = this.standardButtonColor;
            this.setState(() => ({
                btnColors: tempArr
            }));
            await new Promise(resolve => setTimeout(resolve, this.curTimeBtwnFlash)); 
        }
        console.log("done displaying sequence");
        this.setState(() => ({
            mainBtnsDisabled: false,
            ctrlBtnsDisabled: false,
            status: 'You can now repeat the sequence!'
        }));
    }

    render()
    {
        return (
            <Container className="Main">
                <h1>MEMORY MAYHEM</h1>
                <hr></hr>

                {/* main game container buttons */}
                <Container>
                    <Row>
                        <Col>
                            <Button disabled={this.state.mainBtnsDisabled} 
                                    onClick={() => this.btnClick(1)} 
                                    variant={this.state.btnColors[0]} 
                                    className="w-100 h-100">1
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={this.state.mainBtnsDisabled} 
                                    onClick={() => this.btnClick(2)} 
                                    variant={this.state.btnColors[1]}
                                    className="w-100 h-100">2
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={this.state.mainBtnsDisabled} 
                                    onClick={() => this.btnClick(3)} 
                                    variant={this.state.btnColors[2]}
                                    className="w-100 h-100">3
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button disabled={this.state.mainBtnsDisabled} 
                                    onClick={() => this.btnClick(4)} 
                                    variant={this.state.btnColors[3]}
                                    className="w-100 h-100">4
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={this.state.mainBtnsDisabled} 
                                    onClick={() => this.btnClick(5)} 
                                    variant={this.state.btnColors[4]}
                                    className="w-100 h-100">5
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={this.state.mainBtnsDisabled} 
                                    onClick={() => this.btnClick(6)} 
                                    variant={this.state.btnColors[5]}
                                    className="w-100 h-100">6
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button disabled={this.state.mainBtnsDisabled} 
                                    onClick={() => this.btnClick(7)} 
                                    variant={this.state.btnColors[6]}
                                    className="w-100 h-100">7
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={this.state.mainBtnsDisabled} 
                                    onClick={() => this.btnClick(8)} 
                                    variant={this.state.btnColors[7]} 
                                    className="w-100 h-100">8
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={this.state.mainBtnsDisabled} 
                                    onClick={() => this.btnClick(9)} 
                                    variant={this.state.btnColors[8]}
                                    className="w-100 h-100">9
                            </Button>
                        </Col>
                    </Row>
                </Container>

                {/* stats/info container */}
                <Button className="ctrlBtn" size="lg" disabled={this.state.startBtnDisabled} onClick={() => this.startRound()} variant="success">Start Round</Button>
                <Button className="ctrlBtn" size="lg" disabled={this.state.ctrlBtnsDisabled} onClick={() => this.resetGame()} variant="info">Restart Game</Button>
                <Button className="ctrlBtn" size="lg" disabled={this.state.ctrlBtnsDisabled} onClick={() => this.props.handler(false)} variant="info">Return to Menu</Button>
                <h3>LIVES : {this.state.lives} </h3>
                <h3>POINTS: {this.state.points} </h3>
                <h3>LEVEL : {this.state.level} </h3>
                <h3>STATUS: {this.state.status}</h3>
            </Container>
        );
    }
}