import React, { useState }  from 'react';

import Home from './Home';
import Main from './Main';
import defaultSettings from './defaultSettings'; // import default settings

// main controller that holds all the different views we need
export default function App()
{
    // state
    const [inGame, setCurrentView] = useState(false); // if false, we are in main menu; if true, we are in main game (main game & lose overlay)
    
    // default game settings
    const [settings, setSettings] = useState({...defaultSettings});

    return (
        <div>
            {
                inGame ? <Main handler={setCurrentView} settings={settings} /> :
                         <Home handler={setCurrentView} settings={settings} settingsHandler={setSettings}/>
            }
        </div>
    );
}