import React, { useState } from 'react';

// resources
import { Modal, Button, InputGroup, FormControl, Form } from 'react-bootstrap';
import defaultSettings from './defaultSettings'; // import default settings

export default function SettingsModal(props)
{
    const [showing, setShowing] = useState(false);
    const [changed, setChanged] = useState(false);
    
    // to manage intermediate state
    const [randomTimeToFlashGroupsEnabled, setRandomTimeToFlashGroupsEnabled] = useState(props.settings._expEnableRandomTimeToFlashGroups);
    const [randomTimeToFlashGroup, setRandomTimeToFlashGroup] = useState(props.settings._expTimeToFlashGroup);

    const saveSettings = () => 
    {
        const settings = {
            startLives:                 document.querySelector('#optStartLives').value,
            timeBetweenFlashMultiplier: document.querySelector('#optTimeBetweenFlashMultiplier').value,
            timeToFlashMultiplier:      document.querySelector('#optTimeToFlashMultiplier').value,
            standardButtonColor:        document.querySelector('#optStandardButtonColor').value,
            highlightedButtonColor:     document.querySelector('#optHighlightedButtonColor').value,
            pointsOnCorrect:            document.querySelector('#optPointsOnCorrect').value,
            pointsOnIncorrect:          document.querySelector('#optPointsOnIncorrect').value,
            startRoundDelayTime:        document.querySelector('#optStartRoundDelayTime').value,
            timeToFlash:                document.querySelector('#optTimeToFlash').value,
            timeBetweenFlash:           document.querySelector('#optTimeBetweenFlash').value,

            _expEnableRandomTimeToFlashGroups: randomTimeToFlashGroupsEnabled,
            _expTimeToFlashGroup: randomTimeToFlashGroup,
            _expTimeGroupMap: props.settings._expTimeGroupMap // so that we preserve original map
        };

        props.settingsHandler(settings);
        console.log("settings updated");
    }

    // mirrors data in App.jsx settings
    const applyDefaults = () => 
    {
        document.querySelector('#optStartLives').value                  = defaultSettings.startLives;
        document.querySelector('#optTimeBetweenFlashMultiplier').value  = defaultSettings.timeBetweenFlashMultiplier;
        document.querySelector('#optTimeToFlashMultiplier').value       = defaultSettings.timeToFlashMultiplier;
        document.querySelector('#optStandardButtonColor').value         = defaultSettings.standardButtonColor;
        document.querySelector('#optHighlightedButtonColor').value      = defaultSettings.highlightedButtonColor;
        document.querySelector('#optPointsOnCorrect').value             = defaultSettings.pointsOnCorrect;
        document.querySelector('#optPointsOnIncorrect').value           = defaultSettings.pointsOnIncorrect;
        document.querySelector('#optStartRoundDelayTime').value         = defaultSettings.startRoundDelayTime;
        document.querySelector('#optTimeToFlash').value                 = defaultSettings.timeToFlash;
        document.querySelector('#optTimeBetweenFlash').value            = defaultSettings.timeBetweenFlash;

        // have to update state and them DOM due to 'default' behavior - VERY BAD AND UN-REACT LIKE!!!! too lazy to fix for now
        document.querySelector('#opt_expEnableRandomTimeToFlashGroups').checked = defaultSettings._expEnableRandomTimeToFlashGroups;
        document.querySelector('#opt_expToggleTimeToFlashGroupA').checked = defaultSettings._expTimeToFlashGroup === 'A';
        document.querySelector('#opt_expToggleTimeToFlashGroupB').checked = defaultSettings._expTimeToFlashGroup === 'B';
        document.querySelector('#opt_expToggleTimeToFlashGroupC').checked = defaultSettings._expTimeToFlashGroup === 'C';
        setRandomTimeToFlashGroupsEnabled(defaultSettings._expEnableRandomTimeToFlashGroups);
        setRandomTimeToFlashGroup(defaultSettings._expTimeToFlashGroup);
    }
    // test

    return (
        <>
            {/* button to open menu */}
            <Button variant="info" size="lg" className="btn" onClick={() => setShowing(true)}>Settings</Button> 

            {/* actual modal */}
            <Modal show={showing} onHide={() => setShowing(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton >
                    <Modal.Title>Adjust Settings</Modal.Title>
                </Modal.Header>
                <Form onSubmit={(e) => { e.preventDefault(); saveSettings(); setChanged(false); }}>
                <Modal.Body>
                    <p>Adjust any of these settings to your heart's content!</p>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Start Lives</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type="number" min="1" id="optStartLives" defaultValue={props.settings.startLives} onChange={() => setChanged(true)}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Time Between Flash Multiplier</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type="number" min="0" step="0.001" id="optTimeBetweenFlashMultiplier" defaultValue={props.settings.timeBetweenFlashMultiplier} onChange={() => setChanged(true)}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Time To Flash Multiplier</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type="number" min="0" step="0.001" id="optTimeToFlashMultiplier" defaultValue={props.settings.timeToFlashMultiplier} onChange={() => setChanged(true)}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Standard Button Color</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl as="select" id="optStandardButtonColor" defaultValue={props.settings.standardButtonColor} onChange={() => setChanged(true)}>
                                <option>primary</option>
                                <option>secondary</option>
                                <option>success</option>
                                <option>info</option>
                                <option>warning</option>
                                <option>danger</option>
                                <option>light</option>
                                <option>dark</option>
                            </FormControl>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Highlighted Button Color</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl as="select" id="optHighlightedButtonColor" defaultValue={props.settings.highlightedButtonColor} onChange={() => setChanged(true)}>
                                <option>primary</option>
                                <option>secondary</option>
                                <option>success</option>
                                <option>info</option>
                                <option>warning</option>
                                <option>danger</option>
                                <option>light</option>
                                <option>dark</option>
                            </FormControl>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Points on Correct</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type="number" id="optPointsOnCorrect" defaultValue={props.settings.pointsOnCorrect} onChange={() => setChanged(true)}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Points on Incorrect</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type="number" id="optPointsOnIncorrect" defaultValue={props.settings.pointsOnIncorrect} onChange={() => setChanged(true)}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Start Round Delay Time (ms)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type="number" id="optStartRoundDelayTime" defaultValue={props.settings.startRoundDelayTime} onChange={() => setChanged(true)}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Time to Flash (ms)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type="number" id="optTimeToFlash" defaultValue={props.settings.timeToFlash} onChange={() => setChanged(true)}/>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Time Between Flashes (ms)</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl type="number" id="optTimeBetweenFlash" defaultValue={props.settings.timeBetweenFlash} onChange={() => setChanged(true)}/>
                        </InputGroup>
                        
                        <hr/> { /* settings for STAT312 exp specifically */ }

                        <InputGroup>
                            <Form.Check 
                                type="checkbox" 
                                id="opt_expEnableRandomTimeToFlashGroups" 
                                defaultChecked={props.settings._expEnableRandomTimeToFlashGroups} 
                                onChange={(e) => { setChanged(true); setRandomTimeToFlashGroupsEnabled(e.target.checked); }}
                                label="Enable Blind Time to Flash Groups"/>
                        </InputGroup>
                        <InputGroup>
                            <Form.Check 
                                name="timeToFlashGroupSelect" inline 
                                disabled={!randomTimeToFlashGroupsEnabled} 
                                type="radio" 
                                id="opt_expToggleTimeToFlashGroupA" 
                                label="Time A"
                                defaultChecked={props.settings._expTimeToFlashGroup === 'A'}
                                onChange={() => { setChanged(true); setRandomTimeToFlashGroup('A'); }}/>
                            <Form.Check 
                                name="timeToFlashGroupSelect" inline 
                                disabled={!randomTimeToFlashGroupsEnabled} 
                                type="radio" 
                                id="opt_expToggleTimeToFlashGroupB" 
                                label="Time B"
                                defaultChecked={props.settings._expTimeToFlashGroup === 'B'}
                                onChange={() => { setChanged(true); setRandomTimeToFlashGroup('B')}}/>
                            <Form.Check 
                                name="timeToFlashGroupSelect" inline 
                                disabled={!randomTimeToFlashGroupsEnabled} 
                                type="radio" 
                                id="opt_expToggleTimeToFlashGroupC" 
                                label="Time C"
                                defaultChecked={props.settings._expTimeToFlashGroup === 'C'}
                                onChange={() => { setChanged(true); setRandomTimeToFlashGroup('C');}}/>
                        </InputGroup>
                        <small>These settings takes precedent over the "Time to Flash" setting.</small>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={() => { applyDefaults(); setChanged(true); }}>Apply Defaults</Button>
                    <Button variant="success" type="submit" disabled={!changed}>Save Settings</Button>
                    <Button variant="secondary" onClick={() => setShowing(false)}>Close</Button>
                </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}