// this doesn't have any react code, but just exports default settings that
// we can import in other parts of the codebase so that we can
// keep them all in one place

const settings = 
{
    // "standard" settings
    startLives: 3,                                  // amount of lives to start with
    timeBetweenFlashMultiplier: 0.925,              // updates timeBetweenFlash every round
    timeToFlashMultiplier: 0.925,                   // updates timeToFlash every round
    standardButtonColor: 'secondary',               // non-highlighted game button color
    highlightedButtonColor: 'danger',               // highlighted game button color
    pointsOnCorrect: 1,                             // amt. of points to reward on correct btn click
    pointsOnIncorrect: -1,                          // amt. of points to reward on incorrect btn click
    startRoundDelayTime: 350,                       // delay before sequence is displayed on round start
    timeToFlash: 1000,                              // initial interval to flash correct square (in ms)
    timeBetweenFlash: 1000,                         // initial interval between square flashes (in ms)

    // settings specifically for STAT312 exp
    _expEnableRandomTimeToFlashGroups: false,       // enable user to select time group;
    _expTimeToFlashGroup: 'A',                      // which time group is selected
    _expTimeGroupMap: { A: 1500, B: 500, C: 1000 }  // times associated with each group
};

export default settings;