import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

// bootstrap global import
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
    <React.StrictMode><App /></React.StrictMode>,
    document.getElementById('root')
);